import { useState, useEffect } from "react";

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const handler = debounce((e) => setMatches(e.matches));
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, [matches, query]);

  return matches;
};

export default useMediaQuery;
