import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Verein, Impressum, Datenschutz, FourOhFour } from "./pages";

const Router = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to="/verein/index.html" />}
        />
        <Route index path="/verein/index.html" Component={Verein} />

        <Route path="/verein/impressum.html" Component={Impressum} />
        <Route path="/verein/datenschutz.html" Component={Datenschutz} />

        <Route path="*" component={FourOhFour} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
