import React from "react";
import {
  Header,
  Footer,
  Section,
  Title,
  Text,
  Flex,
  Box,
  Label,
  Divider,
} from "../components";
import { useMediaQuery } from "../hooks";

const Impressum = () => {
  const forPhones = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Header />

      <Section title="Kontakt & Impressum" spacing="giga">
        <Flex>
          <Box size="12">
            <Text align="center">
              <Label rusty="light">TSV Merschwitz 1912 e.V.</Label>
            </Text>
            <Title
              priority="1"
              size={forPhones ? "large" : "mega"}
              align="center"
            >
              <Label rusty="dark" slopy="narrow">
                Kontakt & Impressum
              </Label>
            </Title>
          </Box>
        </Flex>
      </Section>

      <Section title="Kontakt und Impressum" spacing="mega">
        <Flex>
          <Box size="12">
            <Title priority="2" size="medium">
              TSV Merschwitz 1912 e.V.
            </Title>
            <Text>
              Seußlitzer Straße 12
              <br />
              01612 Nünchritz OT Merschwitz
            </Text>
            <Text>Mail: kontakt@tsv-merschwitz.de</Text>
            <Text>
              <strong>Registergericht:</strong> Amtsgericht Ort <br />
              <strong>Registernummer:</strong> VR 12105 <br />
            </Text>
            <Text>
              <strong>Gemeinschaftlich vertretungsbefugt:</strong>
              <br />
              Martin Wolf (<strong>Vereinspräsident</strong>) <br />
              Seußlitzer Straße 12 <br />
              01612 Nünchritz OT Merschwitz <br />
              Mail: mw@tsv-merschwitz.de
            </Text>
            <Text>
              <strong>Verantwortliche i.S.d. § 55 Abs. 2 RStV:</strong>
              <br />
              Martin Wolf (<strong>Vereinspräsident</strong>) <br />
              Seußlitzer Straße 12 <br />
              01612 Nünchritz OT Merschwitz <br />
              Mail: mw@tsv-merschwitz.de
            </Text>
          </Box>
        </Flex>
      </Section>

      <Flex>
        <Box size="12">
          <Divider />
        </Box>
      </Flex>

      <Footer />
    </>
  );
};

export default Impressum;
