import React from "react";
import cx from "classnames";
import { Flex, Box } from "../components";
import "./Section.css";

const Section = ({ title, surface, spacing, children }) => {
  return (
    <section
      id={title}
      className={cx([
        "section",
        surface && `section-surface-${surface}`,
        spacing && `section-spacing-${spacing}`,
      ])}
    >
      <Flex align="center">
        <Box size="10">{children}</Box>
      </Flex>
    </section>
  );
};

export default Section;
