import React from "react";
import cx from "classnames";
import "./Title.css";

const Title = ({ align, priority, size, sup, night, children }) => {
  const variants = ["1", "2", "3", "4", "5", "6"];
  const Tag = priority && variants.includes(priority) ? `h${priority}` : "div";

  return (
    <>
      {sup && <p className="title-sup">{sup}</p>}
      <Tag
        className={cx([
          "title",
          size && `title-size-${size}`,
          align && `text-align-${align}`,
          night && "text-night",
        ])}
      >
        {children}
      </Tag>
    </>
  );
};

export default Title;
