import React from "react";
import { createRoot } from "react-dom/client";
import Router from "./Router";
import Theme from "./theme/Theme";

const root = createRoot(document.getElementById("root"));
root.render(
  <Theme>
    <Router />
  </Theme>
);
