import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Flex,
  Box,
  Section,
  Avatar,
  Text,
  Label,
  Divider,
} from "../components";
import "./Header.css";
import Logo from "../theme/tsv.inverted.outline.svg";
import { useMediaQuery } from "../hooks";
import { Modal, Row, Col, Stack } from "react-bootstrap";
import { AiOutlineMenu } from "react-icons/ai";

const Header = () => {
  const { pathname = "" } = useLocation();
  const [openPanel, setOpenPanel] = useState(false);

  const forPhones = useMediaQuery("(max-width: 600px)");

  if (forPhones)
    return (
      <>
        <Section surface="night" spacing="small">
          <Row>
            <Col className="col-2">
              <button
                onClick={() => {
                  setOpenPanel(true);
                }}
              >
                <AiOutlineMenu style={{ color: "white" }} />
              </button>
            </Col>
            <Col className="col-8">
              <div style={{ marginLeft: "40%" }}>
                <Avatar size="large" image={Logo} name="TSV" />
              </div>
            </Col>
          </Row>
        </Section>

        <Modal show={openPanel} fullscreen>
          <Modal.Header
            style={{
              backgroundColor: "black",
              borderBottom: "none",
              position: "relative",
            }}
            className="p-4"
          >
            <button
              style={{
                color: "white",
                width: "40px",
                height: "40px",
                position: "absolute",
                top: "20px",
                right: "20px",
              }}
              onClick={() => {
                setOpenPanel(false);
              }}
            >
              X
            </button>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: "black",
              height: "100vh",
              width: "100vw",
            }}
            className="p-4"
          >
            <Stack gap={1}>
              <Link
                to="/verein/index.html"
                style={{ textDecoration: "none", borderBottom: "none" }}
              >
                <Text size="mega" night>
                  <Label
                    rusty={pathname.startsWith("/verein") ? "dark" : "light"}
                  >
                    Verein
                  </Label>
                </Text>
              </Link>

              <Divider />

              <Link
                to="/verein/dmpressum.html"
                style={{ textDecoration: "none" }}
              >
                <Text size="medium" night>
                  Kontakt & Impressum
                </Text>
              </Link>
              <Link
                to="/verein/datenschutz.html"
                style={{ textDecoration: "none" }}
              >
                <Text size="medium" night>
                  Datenschutz
                </Text>
              </Link>
            </Stack>
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: "black",
              borderTop: "none",
              justifyContent: "left",
              color: "white",
            }}
            className="p-4"
          >
            <Text size="large">
              <Label rusty="light">Mitglied werden</Label>
            </Text>
            <Text size="medium">
              Du willst Teil unser/er/es Merschwitzer Familie/Team/Mannschaft
              werden? Dann freuen wir uns über dich, deine Ideen, deinen
              Sportsgeist und natürlich dich kennenzulernen. (Warum in einen
              Sportverein?, Kopplung zu o. g. Texten)
            </Text>

            <div style={{ paddingTop: "60px" }}>
              <Avatar size="large" image={Logo} name="TSV" />
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );

  return (
    <Section surface="night" spacing="small">
      <div className="header">
        <div className="header-logo">
          <Avatar size="mega" image={Logo} name="TSV" />
        </div>

        <Flex>
          <Box size="5" align="bottom">
            <Text align="left" size="large">
              <Link to="/verein/index.html">
                <Label
                  rusty={pathname.startsWith("/verein") ? "dark" : "light"}
                >
                  Verein
                </Label>
              </Link>
            </Text>
          </Box>

          <Box size="4" offset="3">
            <Flex spacing="evenly">
              <Box size="9">
                <Link to="/verein/impressum.html">
                  <Text align="right" size="small">
                    Kontakt & Impressum
                  </Text>
                </Link>
              </Box>
              <Box size="3">
                <Link to="/verein/datenschutz.html">
                  <Text align="right" size="small">
                    Datenschutz
                  </Text>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </div>
    </Section>
  );
};

export default Header;
