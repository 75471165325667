import React from "react";
import cx from "classnames";
import "./Box.css";

const Box = ({ size, offset, align, gutter, children }) => {
  return (
    <div
      className={cx([
        "box",
        size && `box-size-${size}`,
        offset && `box-offset-${offset}`,
        align && `box-align-${align}`,
        gutter && `box-gutter-${gutter}`,
      ])}
    >
      {children}
    </div>
  );
};

export default Box;
