import React from "react";
import cx from "classnames";
import "./Tag.css";

const Tag = ({ align, size, shape, children }) => {
  return (
    <div
      className={cx([
        "tag",
        align && `tag-align-${align}`,
        size && `tag-size-${size}`,
        shape && `tag-shape-${shape}`,
      ])}
    >
      <span className="tag-layer">{children}</span>
    </div>
  );
};

export default Tag;
