import React from "react";
import { Carousel } from "react-bootstrap";
import {
  Header,
  Footer,
  Section,
  Title,
  Text,
  Label,
  Flex,
  Box,
  Image,
  Texture,
  Divider,
  Tag,
  Hero,
} from "../components";
import { useMediaQuery } from "../hooks";
import BallInFrontOfGoal from "../assets/alter-ball-sportplatz.0495e986.jpg";
import HistorischesFussballBild from "../assets/fußball-tradition.85cb62f2.jpg";
import Festsaal from "../assets/festsaal.jpg";
import Kegelbahn from "../assets/kegelbahn.jpg";
import Klubraum from "../assets/klugraum.jpg";
import Bar from "../assets/bar.jpg";

const Verein = () => {
  const forPhones = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Header />

      <Hero type="verein">
        <Section title="Verein" spacing="giga">
          <Flex>
            <Box size="12">
              <Text align="center">
                <Label rusty="light">TSV Merschwitz 1912 e.V.</Label>
              </Text>
              <Title
                priority="1"
                size={forPhones ? "large" : "mega"}
                align="center"
              >
                <Label rusty="dark" slopy="narrow">
                  Verein
                </Label>
              </Title>
            </Box>
          </Flex>
        </Section>
      </Hero>

      <Section title="Wir" spacing="giga">
        <Flex>
          <Box size="12">
            <Text align="center">
              <Label rusty="light">Ein pragendes</Label>
            </Text>
            <Title priority="3" size="mega" align="center">
              <Label rusty="dark" slopy="narrow">
                WIR
              </Label>
            </Title>
          </Box>
        </Flex>
        <Flex spacing="evenly">
          <Box size={forPhones ? "4" : "2"}>
            <Tag
              shape="round"
              size={forPhones ? "medium" : "large"}
              align="center"
            >
              <Text size="mega" align="center">
                <Label rusty="light">
                  {Math.floor(
                    new Date(Date.now()).getFullYear() -
                      new Date(1912, 1, 1).getFullYear()
                  )}
                </Label>
              </Text>
            </Tag>
            <Text size={forPhones ? "medium" : "large"} align="center">
              <Label rusty="dark" slopy="narrow">
                Jahre Verein
              </Label>
            </Text>
          </Box>
          <Box size={forPhones ? "4" : "2"}>
            <Tag
              shape="round"
              size={forPhones ? "medium" : "large"}
              align="center"
            >
              <Text size="mega" align="center">
                <Label rusty="light">5</Label>
              </Text>
            </Tag>
            <Text size={forPhones ? "medium" : "large"} align="center">
              <Label rusty="dark" slopy="narrow">
                Sektionen
              </Label>
            </Text>
          </Box>

          <Box size={forPhones ? "4" : "2"}>
            <Tag
              shape="round"
              size={forPhones ? "medium" : "large"}
              align="center"
            >
              <Text size="mega" align="center">
                <Label rusty="light">235</Label>
              </Text>
            </Tag>
            <Text size={forPhones ? "medium" : "large"} align="center">
              <Label rusty="dark" slopy="narrow">
                Mitgleider
              </Label>
            </Text>
          </Box>
          <Box size={forPhones ? "4" : "2"}>
            <Tag
              shape="round"
              size={forPhones ? "medium" : "large"}
              align="center"
            >
              <Text size="mega" align="center">
                <Label rusty="light">36</Label>
              </Text>
            </Tag>
            <Text size={forPhones ? "medium" : "large"} align="center">
              <Label rusty="dark" slopy="narrow">
                Supporter
              </Label>
            </Text>
          </Box>
        </Flex>
      </Section>

      <Flex>
        <Box size="10" offset="1">
          <Divider />
        </Box>
      </Flex>

      <Section title="Zusammenhalt" spacing="mega">
        <Flex>
          <Box size={forPhones ? "12" : "6"} gutter="between">
            <Title priority="3" size="medium">
              <Label rusty="dark">Jung und Alt </Label>
              <Label rusty="light">hält zusammen</Label>
            </Title>
            <Text size="medium">
              Nicht nur bei den vielen sportlichen Aktivitäten, sondern auch
              neben der aktiven Beschäftigung steht bei uns Freundschaft und
              Zusammenhalt an oberster Stelle.
            </Text>
            <Text>Bei uns hält Jung und Alt zusammen.</Text>
          </Box>
          <Box size={forPhones ? "12" : "6"} gutter="between">
            <Image
              src={BallInFrontOfGoal}
              name="Ein Ball liegt auf dem Fußballrasen"
            />
          </Box>
        </Flex>
      </Section>

      <Texture type="rusty-background" mode="fixed">
        <Section title="Tradition" spacing="mega">
          <Flex>
            <Box size={forPhones ? "12" : "6"} gutter="between">
              <Image
                src={HistorischesFussballBild}
                name="Historisches Bild: Männer spielen zusammen Fußball"
              />
            </Box>
            <Box size={forPhones ? "12" : "6"} gutter="between">
              <Title priority="3" size="medium" sup="Gemeinsam seit 1912">
                <Label rusty="light">Eine lange Tradition </Label>
                <br />
                <Label rusty="dark">trifft auf Moderne</Label>
              </Title>
              <Text size="medium">
                Seit mehr als 100 Jahren zählen wir zu einem der Urgesteine in
                unserer Region. Begonnen als Turnverein engagierten sich schnell
                viele Sportbegeisterte und gründeten im Laufe der Zeit mehrere
                Sektionen in denen wir mittlerweile aktiv sind. Heute können wir
                mit immerhin 4 Sektionen und 230 aktiven Mitgliedern glänzen.
                Unsere gesamte und ereignisreiche Vereinsgeschichte gibt es
                gedruckt in unserer Chronik nachzulesen.
              </Text>
            </Box>
          </Flex>
        </Section>
      </Texture>

      <Section title="Raumvermietung" spacing="mega">
        <Flex>
          <Box size="12">
            <Title
              priority="2"
              size="medium"
              sup="Wir kennen größere Feierlichkeiten"
            >
              <Label rusty="dark">Raumvermietung</Label>
            </Title>
          </Box>
        </Flex>

        <Carousel variant="dark" indicators={false} data-interval="false">
          <Carousel.Item>
            <Flex>
              <Box size={forPhones ? "12" : "6"} gutter="between">
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Flex align="center">
                      <Box>
                        <Title priority="3" size="large" align="center" night>
                          <Label rusty="dark">Der Saal</Label>
                        </Title>
                      </Box>
                    </Flex>
                  </div>
                  <Image
                    src={Festsaal}
                    height="350px"
                    name="unser Saal im Vereinshaus"
                  />
                </div>
              </Box>
              <Box size={forPhones ? "12" : "6"} gutter="between">
                <Text size="medium">
                  Egal ob Hochzeit, große Familienfeiern oder Versammlungen,
                  unser Saal bietet vor allem für große Feierlichkeiten mit bis
                  zu 140 Sitzplätzen den passenden Rahmen. Das ehrwürdige
                  Ambiente bietet viele individuelle Einrichtungsmöglichkeiten.
                </Text>
                <Text size="medium" type="block">
                  <Label rusty="dark">150 Euro/Tag</Label>
                </Text>
              </Box>
            </Flex>
          </Carousel.Item>

          <Carousel.Item>
            <Flex>
              <Box size={forPhones ? "12" : "6"} gutter="between">
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Flex align="center">
                      <Box>
                        <Title priority="3" size="large" align="center" night>
                          <Label rusty="dark">Die Kegelbahn</Label>
                        </Title>
                      </Box>
                    </Flex>
                  </div>
                  <Image
                    src={Kegelbahn}
                    height="350px"
                    name="unsere Kegelbahn im Vereinshaus"
                  />
                </div>
              </Box>
              <Box size={forPhones ? "12" : "6"} gutter="between">
                <Text size="medium">
                  Kumpelabende, kleine Familienfeiern oder Betriebsfeiern,
                  unsere Kegelbahn bietet die ideale Gelegenheit Ihrer Feier
                  eine sportliche Note zu verleihen. Kann auch in Verbindung mit
                  anderen Räumlichkeiten zu gebucht werden.
                </Text>
                <Text size="medium">
                  <Label rusty="dark">50 Euro/Tag</Label>
                </Text>
              </Box>
            </Flex>
          </Carousel.Item>

          <Carousel.Item>
            <Flex>
              <Box size={forPhones ? "12" : "6"} gutter="between">
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Flex align="center">
                      <Box>
                        <Title priority="3" size="large" align="center" night>
                          <Label rusty="dark">Der Klubraum</Label>
                        </Title>
                      </Box>
                    </Flex>
                  </div>
                  <Image
                    src={Klubraum}
                    height="350px"
                    name="unser Klubraum im Vereinshaus"
                  />
                </div>
              </Box>
              <Box size={forPhones ? "12" : "6"} gutter="between">
                <Text size="medium">
                  Besonders in Verbindung mit unserer Gaststube bietet sich hier
                  die Möglichkeit auch Veranstaltungen im Klubraum mit bis zu 50
                  Personen durchzuführen.
                </Text>
                <Text size="medium">
                  <Label rusty="dark">75 Euro/Tag</Label>
                </Text>
              </Box>
            </Flex>
          </Carousel.Item>

          <Carousel.Item>
            <Flex>
              <Box size={forPhones ? "12" : "6"} gutter="between">
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Flex align="center">
                      <Box>
                        <Title priority="3" size="large" align="center" night>
                          <Label rusty="dark">Die Bar</Label>
                        </Title>
                      </Box>
                    </Flex>
                  </div>
                  <Image
                    src={Bar}
                    height="350px"
                    name="unsere Bar im Vereinshaus"
                  />
                </div>
              </Box>
              <Box size={forPhones ? "12" : "6"} gutter="between">
                <Text size="medium">
                  Jugendweihe, Schulanfang oder Geburtstage, unsere Gaststube
                  bietet im gemütlichen Ambiente für bis zu 35 Personen
                  ausreichend Platz. Hier ist alles gegeben um den Abend an
                  unserer Bar ausklingen zu lassen.
                </Text>
                <Text size="medium">
                  <Label rusty="dark">75 Euro/Tag</Label>
                </Text>
              </Box>
            </Flex>
          </Carousel.Item>
        </Carousel>

        <Text size="medium">
          <br />
          <strong>Anfragen</strong> zur Raumbuchung können am Montag, Mittwoch
          und Donnerstag zwischen 18 bis 19 Uhr im Vereinshaus gestellt werden.
        </Text>
      </Section>

      <Flex>
        <Box size="10" offset="1">
          <Divider />
        </Box>
      </Flex>

      <Footer />
    </>
  );
};

export default Verein;
