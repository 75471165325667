import "./bootstrap.css";
import "./layout.css";
import "./fonts.css";
import "./typography.css";

import "bootstrap/dist/css/bootstrap.min.css";

const Theme = ({ children }) => {
  return children;
};

export default Theme;
