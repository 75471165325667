// @ToDo
// - use Blur component as a lighter/darker overlayer over the texture image (improve contrast to text)

import React from "react";
import cx from "classnames";
import "./Texture.css";

const Texture = ({ type, mode, children }) => {
  return (
    <div
      className={cx(
        "texture",
        type && `texture-type-${type}`,
        mode && `texture-mode-${mode}`
      )}
    >
      {children}
    </div>
  );
};

export default Texture;
