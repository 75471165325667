import React from "react";
import cx from "classnames";
import "./Label.css";

const Label = ({ rusty, slopy, children }) => {
  return (
    <span
      className={cx([
        "label",
        rusty && `label-rusty-${rusty}`,
        slopy && `label-slopy-${slopy}`,
      ])}
    >
      {children}
    </span>
  );
};

export default Label;
