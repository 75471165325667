import React from "react";
import cx from "classnames";
import "./Hero.css";

const Hero = ({ type, children }) => {
  return (
    <div className={cx(["hero", type && `hero-type-${type}`])}>
      <div className="hero-blur"></div>
      <div className="hero-content">{children}</div>
    </div>
  );
};

export default Hero;
