import React from "react";
import {
  Header,
  Footer,
  Section,
  Flex,
  Box,
  Title,
  Text,
  Label,
  Divider,
} from "../components";
import { useMediaQuery } from "../hooks";

const Datenschutz = () => {
  const forPhones = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Header />

      <Section title="Datenschutz" spacing="giga">
        <Flex>
          <Box size="12">
            <Text align="center">
              <Label rusty="light">TSV Merschwitz 1912 e.V.</Label>
            </Text>
            <Title
              priority="1"
              size={forPhones ? "large" : "mega"}
              align="center"
            >
              <Label rusty="dark" slopy="narrow">
                Datenschutz
              </Label>
            </Title>
          </Box>
        </Flex>
      </Section>

      <Section title="Informationen zum Datenschutz" spacing="mega">
        <Flex>
          <Box size="12">
            <Title priority="2" size="medium">
              Präambel
            </Title>
            <Text>
              Diese Erklärung wird unter Beachtung des Bundesdatenschutzgesetzes
              (BDSG) und der ab dem 25.05.2018 geltenden
              EU-Datenschutzgrundverordnung (DSGVO) sowie aller sonstigen
              geltenden Vorschriften des Datenschutzes abgegeben.
            </Text>

            <Title priority="2" size="medium">
              Geltungsbereich des DSGVO
            </Title>
            <Text>
              Die DSGVO gilt für alle natürlichen Personen, öffentliche
              Institutionen sowie für alle Privatunternehmen mit Sitz in der EU,
              die im Rahmen ihrer betrieblichen Tätigkeit personenbezogene Daten
              verarbeiten.
            </Text>

            <Title priority="2" size="medium">
              Personenbezogene Daten
            </Title>
            <Text>
              Personenbezogene Daten sind Einzelangaben über persönliche und
              sachliche Verhältnisse einer bestimmten oder bestimmbaren
              natürlichen Person, z.B. Name, Anschrift, E-Mail-Adresse,
              Telefon-Nr., Geburtsdatum sowie weitere Daten, die der Nutzer dem
              Verein freiwillig zur Verfügung stellt. Diese werden vom Verein
              verarbeitet (Artikel 6 Bas. 1 lit. B DSGVO).
              <br />
              <br />
              Der Verein verarbeitet die Daten, solange die Mitgliedschaft im
              Verein besteht bzw. es zur Erfüllung wechselseitig bestehender
              Pflichten erforderlich ist. Dies erfolgt nur solange, wie
              gesetzliche Pflichten zur Aufbewahrung bzw. Speicherung bestehen.
            </Text>

            <Title priority="2" size="medium">
              Nicht personenbezogene Daten
            </Title>
            <Text>
              Die Daten werden auf der Grundlage des berechtigten Interesses des
              Vereins verarbeitet. Damit wird eine funktionierende Vereinsarbeit
              gewährleistet. Der Verein erhebt nur technische notwendige Daten,
              die automatisch durch die Nutzung der Webseite des Vereins
              übermittelt werden oder die durch technisch notwendige oder
              funktionelle Cookies erforderlich sind und soweit nicht durch
              Registrierung des Nutzers andere Daten an den Verein übermittelt
              werden. <br />
              <br />
              Soweit die Webseite des Vereins genutzt wird, wird sichergestellt,
              dass eine ordnungsgemäße und funktionale Webseite durch den Verein
              betrieben wird. <br />
              <br />
              Der Verein behält sich vor, diese Daten nachträglich zu prüfen.
              Dies erfolgt ausschließlich dann, wenn konkrete Anhaltspunkte für
              eine rechtswidrige Nutzung bekannt werden. <br />
              <br />
              Die Rechtsgrundlage für die Erklärung und Verarbeitung Ihrer Daten
              ergibt sich aus Artikel 6 Absatz 1 lit. a - f EU-DSGVO.
            </Text>

            <Title priority="2" size="medium">
              Datenschutzverantwortlicher
            </Title>
            <Text>
              Verantwortlich für die Datenschutzverarbeitung ist <br />
              <br />
              TSV Merschwitz 1912 e.V. <br />
              vertreten durch den Vorstand, Herrn Martin Wolf <br />
              Seußlitzer Straße 12, 01612 Nünchritz OT Merschwitz
            </Text>

            <Title priority="2" size="medium">
              Rechte der betroffenen Personen
            </Title>

            <strong>1. Recht auf Auskunft</strong>
            <Text>
              Hierzu gehören:
              <br />- die zur eigenen Person gespeicherten Daten einschließlich
              der Angaben, woher sie stammen und an wen sie weitergegeben werden{" "}
              <br />- die Angaben über Zweck der Speicherung
            </Text>

            <strong>2. Recht auf Einsicht</strong>
            <Text>
              Sie haben jederzeit das Recht auf Einsichtnahme in die bezogenauf
              Ihre Person erhobenen Daten.
            </Text>

            <strong>3. Recht auf Einschränkung der Verarbeitung</strong>
            <Text>
              Unter den gesetzlichen Voraussetzungen können Sie die
              Einschränkung der Verarbeitung der sie betreffenden Daten
              verlangen.
            </Text>

            <strong>
              4. Recht auf Berichtigung und / oder Vervollständigung
            </strong>
            <Text>
              Das Recht auf Berichtigung besteht gegenüber dem Verantwortlichen,
              sofern die verarbeiteten Daten, die Sie betreffen, unrichtig oder
              unvollständig sind.
            </Text>

            <strong>5. Recht auf Löschung</strong>
            <Text>
              Sie können von dem Verantwortlichen verlangen, die Daten zu
              löschen, wenn die Daten nicht im Einklang mit den gesetzlichen
              Bestimmungen verarbeitet wurden.
            </Text>

            <strong>6. Allgemeines Widerspruchsrecht</strong>
            <Text>
              Dieses Recht besteht unter den gesetzlich geregelten
              Voraussetzungen Das Widerspruchsrecht besteht nicht, wenn einen
              Rechtsvorschrift die Erhebung, Verarbeitung oder Nutzung
              vorschreibt.
            </Text>

            <strong>
              7. Recht auf Widerruf der datenschutzrechtlichen
              Einwilligungserklärung
            </strong>
            <Text>
              Sie haben das Recht, Ihre datenschutzrechtliche
              Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf
              der Einwilligungserklärung wird die Rechtmäßigkeit der aufgrund
              der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
              berührt.
            </Text>

            <strong>8. Recht auf Beschwerde bei einer Aufsichtsbehörde</strong>
            <Text>
              Unabhängig eines ggf. bestehenden verwaltungsrechtlichen oder
              gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde
              bei einer Aufsichtsbehörde, insbesondere in der Bundesrepublik
              Deutschland zu, wenn Sie davon ausgehen, dass ihre Daten durch den
              Verein nicht entsprechend den gesetzlichen Bestimmungen der DSGVO
              verarbeitet werden.
            </Text>

            <strong>9. Schlussbestimmung</strong>
            <Text>
              Sofern Fragen im Zusammenhang mit der Datenschutzerklärung
              ,besteht, bitten wir Sie, den Verein zu kontaktieren.
            </Text>
          </Box>
        </Flex>
      </Section>

      <Flex>
        <Box size="12">
          <Divider />
        </Box>
      </Flex>

      <Footer />
    </>
  );
};

export default Datenschutz;
