import React from "react";
import { Link } from "react-router-dom";
import { Flex, Box, Section, Text, Avatar } from "../components";
import cx from "classnames";
import "./Footer.css";
import Logo from "../theme/tsv.normal.svg";
import { useMediaQuery } from "../hooks";

const Footer = () => {
  const forPhones = useMediaQuery("(max-width: 600px)");

  if (forPhones)
    return (
      <Section spacing="small">
        <div className="footer">
          <Flex>
            <Box size="12">
              <div className={cx(["footer-logo"])}>
                <Avatar size="large" image={Logo} name="TSV" />
              </div>

              <Text size="medium">
                <strong>TSV Merschwitz 1912 e.V.</strong>
                <br />
                Seußlitzer Straße 12
                <br />
                01612 Merschwitz
                <br />
              </Text>
            </Box>
          </Flex>
        </div>
      </Section>
    );

  return (
    <Section spacing="small">
      <div className="footer">
        <Flex>
          <Box size="8">
            <div className={cx(["footer-logo"])}>
              <Avatar size="large" image={Logo} name="TSV" />
            </div>

            <Text size="medium">
              <strong>TSV Merschwitz 1912 e.V.</strong>
              <br />
              Seußlitzer Straße 12
              <br />
              01612 Merschwitz
              <br />
            </Text>
          </Box>

          <Box size="4" align="bottom">
            <Flex spacing="evenly">
              <Box size="9">
                <Link to="/verein/impressum.html">
                  <Text align="right" size="small">
                    Kontakt & Impressum
                  </Text>
                </Link>
              </Box>
              <Box size="3">
                <Link to="/verein/datenschutz.html">
                  <Text align="right" size="small">
                    Datenschutz
                  </Text>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </div>
    </Section>
  );
};

export default Footer;
