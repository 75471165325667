import React from "react";
import cx from "classnames";
import "./Text.css";

const Text = ({ align, type, size, night, children }) => {
  const Tag = type && type === "block" ? "div" : "p";

  return (
    <Tag
      className={cx([
        "text",
        align && `text-align-${align}`,
        size && `text-size-${size}`,
        night && `text-night`,
      ])}
    >
      {children}
    </Tag>
  );
};

export default Text;
