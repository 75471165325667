import React from "react";
import cx from "classnames";
import "./Flex.css";

const Flex = ({ align, spacing, children }) => {
  return (
    <div
      className={cx([
        "flex",
        align && `align-${align}`,
        spacing && `spacing-${spacing}`,
      ])}
    >
      {children}
    </div>
  );
};

export default Flex;
