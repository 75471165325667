// @ToDo
// - loading State: show placeholder skeleton

import React from "react";
import "./Image.css";

const Image = ({ src, name, height }) => {
  return (
    <img
      className="image"
      src={`${src}`}
      alt={name}
      title={name}
      height={height}
    />
  );
};

export default Image;
