import React from "react";
import cx from "classnames";
import "./Avatar.css";

const Avatar = ({ shape, size, fill, image, name }) => {
  return (
    <span className={cx(["avatar", size && `avatar-size-${size}`])}>
      {image && (
        <img
          className={cx([
            "avatar-image",
            "avatar-plate",
            shape && `avatar-shape-${shape}`,
          ])}
          src={`${image}`}
          title={name}
          alt={name}
        />
      )}
      {fill && (
        <span
          className={cx(["avatar-plate", shape && `avatar-shape-${shape}`])}
          style={{ "backgroundColor": fill }}
        ></span>
      )}
    </span>
  );
};

export default Avatar;
