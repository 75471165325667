import React from "react";
import {
  Header,
  Footer,
  Section,
  Flex,
  Box,
  Label,
  Title,
  Text,
  Divider,
} from "../components";

const FourOhFour = () => {
  return (
    <>
      <Header />
      <Section title="404" spacing="giga">
        <Flex>
          <Box size="12">
            <Text align="center">
              <Label rusty="light">TSV Merschwitz 1912 e.V.</Label>
            </Text>
            <Title priority="1" size="giga" align="center">
              <Label rusty="dark" slopy="narrow">
                404
              </Label>
            </Title>
          </Box>
        </Flex>
      </Section>

      <Flex>
        <Box size="10" offset="1">
          <Divider />
        </Box>
      </Flex>

      <Footer />
    </>
  );
};

export default FourOhFour;
